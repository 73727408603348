import React, { useEffect, useState } from "react";
import { useKeycloakToken } from '../common/useKeycloak';
import AddConnectionDetails from "./AddConnectionDetails";
import { useNavigate, useParams } from 'react-router-dom';
import { Connection, buildDestination, Destination, DestinationType, NewIntegration, Organization, ProviderType, buildProviderType, buildNewIntegration, ConnectionConfiguration } from "../common/ObjectTypes";
import { addConnectionDestinations, createNewConnection, getConnectionDetails } from "../../api/connections/connectionsApi";
import Spinner from "../common/Spinner";
import { getDestinationDetails, getDestinationsList } from "../../api/destinations/destinationsApi";
import { filterDestinationOwner } from "../destinations/DestinationHelperFunctions";
import { toast } from "react-toastify";
import { ManageDestinationModal } from "../destinations/ManageDestinationModal";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import loadOrganizations from "../../api/organizations/loadOrganizations";
import { page_size } from "../../api/apiUtils";
import loadDestinationTypes from '../../api/destinations/loadDestinationTypes';
import loadProviderTypes from '../../api/connections/loadProviderTypesNewConnection';
import { useTranslation } from "react-i18next";
import { ManageDestinationDetails } from "../destinations/ManageDestinationDetails";
import NewConnectionStepThree from "./NewConnectionStepThree";

export function ManageAddConnectionStepThree() {
    type FilterTypes = {
        endpoint__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    function createFilterTypes(
        endpoint__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    ): FilterTypes {
        return {
            endpoint__in: endpoint__in || [],
            enabled: enabled || [],
            type__in: type__in || [],
            owner__in: owner__in || [],
            ordering: ordering || "",
            search: search || ""
        }
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const { t } = useTranslation();
    const { connection_id } = useParams();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const [destinationFilter, setDestinationFilter] = useState<FilterTypes>(() => {
        const savedFilter = localStorage.getItem('newConnectionDestinationFilter');
        return savedFilter ? JSON.parse(savedFilter) : createFilterTypes([], [], [], [], "", "")
    });
    const [initialConnection, setInitialConnection] = useState<NewIntegration>(buildNewIntegration(true))
    const [createdConnection, setCreatedConnection] = useState<Connection>()
    const [updatedConfigurations, setUpdatedConfigurations] = useState<any[]>([]);
    const [errors, setErrors] = useState({});
    const [rsjfErrors, setRsjfErrors] = useState<any[]>([]);
    const [routeId, setRouteId] = useState("");
    const getSaveButtonClassName = (step: number, disabled: boolean): string => {
        if (step === 1) {
            return disabled
                ? "bg-gray-lines text-white"
                : "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 2) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 3) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        return "";
    };
    /* 
        CONNECTION DESTINATIONS:
            - "destinations" array is for destinations already associated with the connection
            - "existingDestPhase0" is for existing destinations that are available to add and unselected
            - "existingDestPhase1" is for existing destinations that have been checked and selected to add (yet to be sorted)
            - "existingDestPhase2" is for selected destinations that are sorted to the top of the available list so they remain there
            regardless of search/filter results
            - "existingDestPhase3" is for unselected destination that are still in their sorted position at the top of the available list
    */
    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [existingDestPhase0, setExistingDestPhase0] = useState<Destination[]>(() => {
        const savedDestinations = localStorage.getItem('existingDestPhase0');
        return savedDestinations ? JSON.parse(savedDestinations) : []
    });
    const [existingDestPhase1, setExistingDestPhase1] = useState<Destination[]>(() => {
        const savedDestinations = localStorage.getItem('existingDestPhase1');
        return savedDestinations ? JSON.parse(savedDestinations) : []
    });
    const [existingDestPhase2, setExistingDestPhase2] = useState<Destination[]>(() => {
        const savedDestinations = localStorage.getItem('existingDestPhase2');
        return savedDestinations ? JSON.parse(savedDestinations) : []
    });
    const [existingDestPhase3, setExistingDestPhase3] = useState<Destination[]>(() => {
        const savedDestinations = localStorage.getItem('existingDestPhase3');
        return savedDestinations ? JSON.parse(savedDestinations) : []
    });
    const [reloadDestinations, setReloadDestinations] = useState(true);

    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [reloadProviderTypes, setReloadProviderTypes] = useState(true);
    const [providerTypesOffset, setProviderTypesOffset] = useState<number>(0);
    const [providerTypesTotal, setProviderTypesTotal] = useState<number>(0);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destinationTypesOffset, setDestinationTypesOffset] = useState<number>(0);
    const [loadingDestinationTypes, setLoadingDestinationTypes] = useState(false);
    const [loadMoreDestinationTypes, setLoadMoreDestinationTypes] = useState(true);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [destDetailsModal, setDestDetailsModal] = useState<{ isOpen: boolean; destId: string | null }>({
        isOpen: false,
        destId: null,
    });

    const [selectedProviderType, setSelectedProviderType] = useState<ProviderType>(buildProviderType());
    const [destinationOffset, setDestinationOffset] = useState<number>(0);
    const [isProviderTypeSelected, setIsProviderTypeSelected] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [currentStep, setCurrentStep] = useState(3);
    const navigate = useNavigate();
    const [addDestModal, setAddDestModal] = useState(false);
    const [addExistingDest, setAddExistingDest] = useState(localStorage.getItem("addExistingDest") ? localStorage.getItem("addExistingDest") === "true" ? true : false : false);
    const [updatingDestinations, setUpdatingDestinations] = useState(false);

    const loadConnectionDetails = async () => {
        if (token && typeof token === 'string' && connection_id) {
            try {
                const tempConnection = await getConnectionDetails(token, connection_id);
                setRouteId(tempConnection.default_route.id);
                if (!localStorage.getItem('newConnectionDestinationFilter')) {
                    setDestinationFilter(filterDestinationOwner({
                        target: {
                            "name": tempConnection.owner.name,
                            "value": tempConnection.owner.id,
                        }
                    }, destinationFilter));
                }
                setCreatedConnection(tempConnection);
                setPageLoaded(true);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            setLoadingOrganizations(true);
            if (!error) {
                setOrganizations(orgs);
                if (offset + page_size >= total_orgs) {
                    setLoadMoreOrganizations(false);
                }
                setLoadingOrganizations(false);
            }
        }
    }

    const loadDestinationTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            const [dest_types, total_dest_types, error] = await loadDestinationTypes(token, offset, search_str, destinationTypes);
            setLoadingDestinationTypes(true);
            if (!error) {
                setDestinationTypes(dest_types);
                if (offset + page_size >= total_dest_types) {
                    setLoadMoreDestinationTypes(false);
                }
                setLoadingDestinationTypes(false);
            }
        }
    }
    const loadProviderTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            setReloadProviderTypes(true);
            const [provider_types, total_provider_types, error] = await loadProviderTypes(token, offset, search_str, providerTypes);
            if (!error) {
                setProviderTypes(provider_types);
                setProviderTypesTotal(total_provider_types);
                setReloadProviderTypes(false);
            }
            setPageLoaded(true);

        }
    }

    useEffect(() => {
        localStorage.setItem('newConnectionDestinationFilter', JSON.stringify(destinationFilter));
        const loadDestinations = async (destArray: Destination[]) => {
            if (token && typeof token === 'string') {
                setExistingDestPhase2(existingDestPhase2.concat(existingDestPhase1));
                setExistingDestPhase3(existingDestPhase2.concat(existingDestPhase1));
                localStorage.setItem("existingDestPhase2", JSON.stringify(existingDestPhase2.concat(existingDestPhase1)));
                localStorage.setItem("existingDestPhase3", JSON.stringify(existingDestPhase2.concat(existingDestPhase1)));
                setUpdatingDestinations(true);
                try {
                    const destinationFilterQuery = {
                        endpoint__in: destinationFilter.endpoint__in.map(a => a.id).toString(),
                        enabled: destinationFilter.enabled.map(a => a.id).toString(),
                        type__in: destinationFilter.type__in.map(a => a.id).toString(),
                        owner__in: destinationFilter.owner__in.map(a => a.id).toString(),
                        ordering: destinationFilter.ordering,
                        search: destinationFilter.search,
                        offset: destinationOffset,
                        previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (destinationOffset - page_size),
                        next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (destinationOffset + page_size)
                    }
                    const temp = await getDestinationsList(token, destinationFilterQuery);

                    // check to see if destination in temp.result is in the selected array
                    const tempDestArray: Destination[] = temp.results.filter((b: Destination) => !destArray.map((a: Destination) => a.id).includes(b.id))

                    setExistingDestPhase0(tempDestArray);
                    localStorage.setItem("existingDestPhase0", JSON.stringify(tempDestArray));
                    localStorage.setItem("existingDestPhase1", JSON.stringify([]));
                    setExistingDestPhase1([]);
                    setUpdatingDestinations(false);
                    setReloadDestinations(false);
                } catch (err) {
                    console.log(err)
                }
            }
        }


        if (reloadProviderTypes) loadProviderTypesData(providerTypesOffset, "");

        if (reloadDestinations) loadDestinations([...destinations, ...existingDestPhase1, ...existingDestPhase2]);
        loadOrganizationsData(organizationOffset, "");
        loadDestinationTypesData(destinationTypesOffset, "");
        if (!pageLoaded) loadConnectionDetails();
    }, [token, reloadDestinations, providerTypes])


    const handleToggleDestinationDetailsModal = async (destId?: string) => {
        if (!destId) {
            // Close modal and remove destination details from URL
            setDestDetailsModal({ isOpen: false, destId: null });
            navigate(`/addconnection/configuredestinations/${connection_id}`);
            return;
        }

        // Open modal and update URL
        navigate(`/addconnection/configuredestinations/${connection_id}/destination/${destId}/details`);
        if (!destDetailsModal.isOpen || destDetailsModal.destId !== destId) {
            setDestDetailsModal({ isOpen: true, destId: destId });
        }
    };

    const handleSelectExistingDestination = (dest: Destination) => {
        let tempPhase0Array: Destination[] = [...existingDestPhase0];
        let tempPhase1Array: Destination[] = [...existingDestPhase1];
        let tempPhase2Array: Destination[] = [...existingDestPhase2];
        let tempPhase3Array: Destination[] = [...existingDestPhase3];

        const indexInPhase0 = tempPhase0Array.findIndex((a: Destination) => a.id === dest.id);
        const indexInPhase1 = tempPhase1Array.findIndex((a: Destination) => a.id === dest.id);
        const indexInPhase2 = tempPhase2Array.findIndex((a: Destination) => a.id === dest.id);
        const indexInPhase3 = tempPhase3Array.findIndex((a: Destination) => a.id === dest.id);

        if (indexInPhase0 !== -1) {
            if (indexInPhase1 !== -1) {
                // Remove from phase 1
                tempPhase1Array.splice(indexInPhase1, 1);
                localStorage.setItem("existingDestPhase1", JSON.stringify(tempPhase1Array));
                setExistingDestPhase1(tempPhase1Array);
            } else {
                // Add to phase 1
                tempPhase1Array.push(dest);
                setExistingDestPhase1(tempPhase1Array);
                localStorage.setItem("existingDestPhase1", JSON.stringify(tempPhase1Array));
            }
        }

        if (indexInPhase3 !== -1) {
            if (indexInPhase2 !== -1) {
                // Remove from phase 2
                tempPhase2Array.splice(indexInPhase2, 1);
                setExistingDestPhase2(tempPhase2Array);
                localStorage.setItem("existingDestPhase2", JSON.stringify(tempPhase2Array));
            } else {
                // Add to phase 2
                tempPhase2Array.push(dest);
                setExistingDestPhase2(tempPhase2Array);
                localStorage.setItem("existingDestPhase2", JSON.stringify(tempPhase2Array));
                // Remove from phase 1
                // Remove from phase 1
                tempPhase1Array.splice(indexInPhase1, 1);
                setExistingDestPhase1(tempPhase1Array);
                localStorage.setItem("existingDestPhase1", JSON.stringify(tempPhase1Array));
            }
        }
    }

    const handleToggleAddDestinationModal = async (openDest_id?: string) => {
        setAddDestModal(!addDestModal)
        if (openDest_id) {
            if (token && typeof token === 'string') {
                try {
                    const temp_destination = await getDestinationDetails(token, openDest_id)
                    handleSelectExistingDestination(temp_destination);
                    setAddExistingDest(true);
                    localStorage.setItem("addExistingDest", "true");
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }

    const clearExistingDestArrays = () => {
        localStorage.removeItem("existingDestPhase0");
        localStorage.removeItem("existingDestPhase1");
        localStorage.removeItem("existingDestPhase2");
        localStorage.removeItem("existingDestPhase3");
        setExistingDestPhase0([]);
        setExistingDestPhase1([]);
        setExistingDestPhase2([]);
        setExistingDestPhase3([]);
    }

    const handleAddSelectedExistingDestinations = async () => {
        const destination_ids = {
            "destinations": existingDestPhase1.map(a => a.id).concat(destinations.map(a => a.id), existingDestPhase2.map(a => a.id))
        }
        setUpdatingDestinations(true);
        if (token && typeof token === 'string') {
            try {
                await addConnectionDestinations(token, routeId, destination_ids);
                setDestinations(existingDestPhase1.concat(destinations, existingDestPhase2));
                toast.success(`${t('Connection updated')}`, {
                    position: toast.POSITION.TOP_RIGHT
                });

                localStorage.removeItem("addExistingDest");
                localStorage.removeItem("newConnectionDestinationFilter");
                navigate("/connections/" + createdConnection?.id + "/destinations");
                clearExistingDestArrays();
            } catch (error) {
                console.log(error)
            }
        }

        setUpdatingDestinations(false);
        setAddExistingDest(false);
        localStorage.setItem("addExistingDest", "false");
    }

    const handleClickAddDestination = (existing: boolean) => {
        if (existing) {
            clearExistingDestArrays()
            setAddExistingDest(!addExistingDest)
            localStorage.setItem("addExistingDest", (!addExistingDest).toString());
        } else {
            setAddDestModal(true);
        }
    }

    const handleChangeDestinationStateFilter = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.enabled
        // active was toggled
        if (e.target.value === "active") {
            if (tempArray.map(a => a.id).includes("true")) {
                let removed: FilterTags[] = []
                for (var item in tempArray) {
                    if (tempArray[item].id !== "true") {
                        removed.push(tempArray[item])
                    }
                    setDestinationFilter((prevFilter: any) => ({
                        ...prevFilter,
                        [e.target.id]: removed
                    }))
                }
            } else {
                const newItem = {
                    name: "Active",
                    id: "true",
                    attribute_name: "enabled"
                }
                tempArray.push(newItem);
                setDestinationFilter((prevFilter: any) => ({
                    ...prevFilter,
                    [e.target.id]: tempArray
                }))
            }

        } else { // inactive was toggled
            if (tempArray.map(a => a.id).includes("false")) {
                let removed: FilterTags[] = []
                for (item in tempArray) {
                    if (tempArray[item].id !== "false") {
                        removed.push(tempArray[item])
                    }
                    setDestinationFilter((prevFilter: any) => ({
                        ...prevFilter,
                        [e.target.id]: removed
                    }))
                }
            } else {
                const newItem = {
                    name: "Inactive",
                    id: "false",
                    attribute_name: "enabled"
                }
                tempArray.push(newItem);
                setDestinationFilter((prevFilter: any) => ({
                    ...prevFilter,
                    [e.target.id]: tempArray
                }))
            }
        }
        setReloadDestinations(true);
    }

    const handleChangeDestinationTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationTypesData(destinationTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationTypes = () => {
        setDestinationTypesOffset(destinationTypesOffset + page_size);
        loadDestinationTypesData(destinationTypesOffset + page_size, "");
    }

    const handleChangeDestinationTypeFilter = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.type__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            const newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setReloadDestinations(true);
    }

    const handleChangeDestinationUrlSearchBar = (e: any) => {
        console.log(e.target.value)
    }

    const handleChangeDestinationOwnerFilter = (e: any) => {
        let tempArray: FilterTags[] = destinationFilter.owner__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            const newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setReloadDestinations(true);
    }

    const handleGlobalDestinationSearch = (searchSubStr: string) => {
        setDestinationFilter((prevFilter: any) => ({
            ...prevFilter,
            search: searchSubStr
        }));
        setReloadDestinations(true);
    }

    const handleRemoveDestinationFilterTag = (e: any) => {
        if (destinationFilter.type__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = destinationFilter.type__in
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                type__in: removed
            }))
        } else if (destinationFilter.owner__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = destinationFilter.owner__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                owner__in: removed
            }))
        } else if ((destinationFilter.endpoint__in.map(a => a.id).includes(e))) {
            let tempArray: FilterTags[] = destinationFilter.endpoint__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                endpoint__in: removed
            }))
        } else {
            // enabled
            let tempArray: FilterTags[] = destinationFilter.enabled
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setDestinationFilter((prevFilter: any) => ({
                ...prevFilter,
                enabled: removed
            }))
        }
        setReloadDestinations(true);
    }

    const handleResetDestinationFilters = (e: any) => {
        setDestinationFilter({
            endpoint__in: [],
            enabled: [],
            type__in: [],
            owner__in: [],
            ordering: "",
            search: ""
        });
        setReloadDestinations(true);
    }

    const formIsValid = () => {
        let form_errors: any = {};
        let rsjf_errors: any = {};
        if (initialConnection.name.length === 0) form_errors.name = "Name is required."
        if (initialConnection.name.length > 50) form_errors.name = "Name cannot exceed 50 characters.";
        if (initialConnection.base_url.length > 0) {
            try {
                new URL(initialConnection.base_url);
            } catch (err) {
                form_errors.base_url = "Enter a valid url including the scheme."
            }
        }
        if (initialConnection.owner === "") form_errors.owner = "Select an organization."

        setErrors(form_errors)
        updatedConfigurations.forEach((config: any, index: number) => {
            const schema_index = selectedProviderType.actions.findIndex((a: any) => a.id === config.action);
            const ajv = new Ajv({ allErrors: true, verbose: true });
            ajv.addKeyword({
                keyword: 'is_executable',
                validate: function () {
                    return true;
                },
                errors: false
            });
            addFormats(ajv);

            const schema = selectedProviderType.actions[schema_index].schema;
            const validate = ajv.compile(schema);
            const data = { ...config.data };

            if (schema.properties && typeof schema.properties === 'object') {
                Object.keys(schema.properties).forEach((key) => {
                    if (!data.hasOwnProperty(key)) {
                        data[key] = null;
                    } else {
                        if (data[key] === undefined) {
                            data[key] = null; // or any default value you prefer
                        }
                    }
                });
            } else {
                console.error('schema.properties is not defined or is not an object');
            }

            const valid = validate(data);
            if (!valid) {
                validate.errors?.forEach((error: any) => {
                    console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    const field = error.instancePath.slice(1);
                    if (!rsjf_errors[selectedProviderType.actions[schema_index].id]) {
                        rsjf_errors[selectedProviderType.actions[schema_index].id] = {};
                    }
                    rsjf_errors[selectedProviderType.actions[schema_index].id][field] = `Error in field '${field}': ${error.message}`;
                });
            }
        });

        setRsjfErrors(rsjf_errors);
        return Object.keys(form_errors).length === 0 && Object.keys(rsjf_errors).length === 0;
    }

    const handleCreateConnection = async (connectionToAdd: NewIntegration) => {
        if (!formIsValid()) return;
        if (token && typeof token === 'string' && formIsValid()) {
            const temp = await createNewConnection(token, connectionToAdd);
            setCreatedConnection(temp)
            setRouteId(temp.default_route.id);
            toast.success(`${t('New connection made, ready to add destinations')}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const handleClickNext = (newStep: number) => {
        if (newStep === 2) setCurrentStep(newStep)
        if (newStep === 3) {
            if (!formIsValid()) {
                setCurrentStep(currentStep)
                return
            }
            else {
                handleCreateConnection(initialConnection);
                setCurrentStep(newStep)
            }
        }
        if (newStep === 4) {
            handleAddSelectedExistingDestinations();
            setCurrentStep(newStep)
        }

    }

    const handleChangeOrganizationSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadOrganizationsData(organizationOffset, e.target.value);
        }
    }

    const handleLoadMoreOrganizations = () => {
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    const handleCancel = () => {
        navigate("/")
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                {addDestModal &&
                    <ManageDestinationModal
                        onToggleModal={handleToggleAddDestinationModal}
                    />
                }
                {destDetailsModal.isOpen &&
                    <ManageDestinationDetails />
                }
                <div className="bg-white shadow static inset-x-0 px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                    <div className="flex flex-col">
                        {currentStep === 1 && <div>
                            <h1 className="text-3xl font-semibold mb-3">{t("Create a Connection")}</h1>
                        </div>}
                        {currentStep > 1 && <div>
                            <h1 className="text-3xl font-semibold mb-3">{t("New")} {selectedProviderType.name} {t("Connection")}</h1>
                            {/* tabs for provider and destinations */}
                            <div className="w-full flex flex-row font-semibold text-medium-gray">
                                <label className={currentStep === 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                    {t("Connect Provider")}
                                </label>
                                <label className={currentStep > 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                    {t("Connect Destinations")}
                                </label>
                            </div>
                        </div>}
                    </div>

                </div>
                <div className="px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                    <NewConnectionStepThree
                        destinations={destinations}
                        existingDestPhase0={existingDestPhase0}
                        existingDestPhase1={existingDestPhase1}
                        existingDestPhase2={existingDestPhase2}
                        existingDestPhase3={existingDestPhase3}
                        onSelectExistingDestination={handleSelectExistingDestination}
                        destinationTypes={destinationTypes}
                        organizations={organizations}
                        filters={destinationFilter.endpoint__in
                            .concat(destinationFilter.type__in, destinationFilter.owner__in, destinationFilter.enabled)}
                        onChangeStateFilter={handleChangeDestinationStateFilter}
                        onChangeTypeFilter={handleChangeDestinationTypeFilter}
                        onChangeDestinationTypeSearchBar={handleChangeDestinationTypeSearchBar}
                        loadingDestinationTypes={loadingDestinationTypes}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onLoadMoreDestinationTypes={handleLoadMoreDestinationTypes}
                        onChangeOwnerFilter={handleChangeDestinationOwnerFilter}
                        onChangeOwnerSearchBar={handleChangeOrganizationSearchBar}
                        onLoadMoreOrganizations={handleLoadMoreOrganizations}
                        loadingOrganizations={loadingOrganizations}
                        loadMoreOrganizations={loadMoreOrganizations}
                        onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                        onGlobalDestinationFilter={handleGlobalDestinationSearch}
                        onRemoveDestinationFilterTag={handleRemoveDestinationFilterTag}
                        onResetDestinationFilters={handleResetDestinationFilters}
                        pageLoaded={pageLoaded}
                        addExistingDest={addExistingDest}
                        updatingDestinations={updatingDestinations}
                        onClickAddDestination={handleClickAddDestination}
                        onAddExistingDestinations={handleAddSelectedExistingDestinations}
                        onViewDetails={handleToggleDestinationDetailsModal}
                    />
                </div>

                <div className="fixed flex flex-row bottom-0 right-0 pr-5 py-3 pl-16 bg-white/10 backdrop-blur w-full shadow-lg">
                    <button className={`px-8 py-2 font-bold rounded mr-3 ${getSaveButtonClassName(currentStep, !isProviderTypeSelected)}`}
                        onClick={() => handleClickNext(currentStep + 1)}
                    >
                        {currentStep === 3 ? t("Finish") : t("Next")}
                    </button>
                    <button className="text-secondary-gray font-bold rounded py-2 px-3 mx-2"
                        type="button"
                        onClick={handleCancel}
                    >
                        {t("Cancel")}
                    </button>
                </div>
            </div>
        )
}